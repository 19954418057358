<!-- 站点异常警告-->
<template>
  <div>
     <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          v-model="query.stationId"
          placeholder="请选择站点"
          clearable
          filterable
          @change="getList(1)"
        >
         
          <el-option
            v-for="(item, index) in stationList.list"
            :key="index"
            :label="item.stationName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="query.type"
          placeholder="请选择类别"
          filterable
          clearable
          @change="getList(1)"
        >
         
          <el-option
            v-for="(item, index) in types"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

       <!-- <el-col :span="10">
        <eb-date-range v-model="dateRange" @change="changeDate"></eb-date-range>
      </el-col> -->

      <el-col :span="4">
        <el-input
          v-model="query.warninfo"
          placeholder="异常信息"
          :maxLength="50"
          clearable
        ></el-input>
      </el-col>

        <el-col :span="4">
        <el-input
          v-model="query.roomCode"
          placeholder="仓门"
          :maxLength="50"
          clearable
        ></el-input>
      </el-col>

       <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>

    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="站点名称" prop="stationName"></el-table-column>
      <el-table-column label="站点地址" prop="address"></el-table-column>
      <el-table-column label="编号" prop="stationMacid"></el-table-column>
      <el-table-column label="异常信息" prop="warninfo"></el-table-column>
      <el-table-column label="异常时间" prop="createAt"></el-table-column>
      <el-table-column label="仓门" prop="roomCode"></el-table-column>
      <el-table-column label="处理描述" prop="doinfo"></el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
 
<script>
import { fetchStationWarningList } from "@/api/station";
import { mapState } from "vuex";
import moment from 'moment';

// 0未知 1可换数量低，2高温异常 3雨水异常 4仓门未关 5烟感异常 6市电故障
const types = [
  {name: "未知", value: 0},
  {name: "可换数量低", value: 1},
  {name: "高温异常", value: 2},
  {name: "雨水异常", value: 3},
  {name: "仓门未关", value: 4},
  {name: "烟感异常", value: 5},
  {name: "市电故障", value: 6},

]
export default {
  computed: mapState({
    stationList(state) {
      return state.station.stationList || { map: {}, list: [] };
    },
  }),
  data() {
    let startTime = moment().subtract(1, "d").startOf("d").format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().startOf("d").format("YYYY-MM-DD HH:mm:ss");
    return {
      types:types,
      dateRange: [startTime, endTime],
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
      },
      total: 0,
    };
  },
  methods: {

    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0])
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1])
          .format("YYYY-MM-DD HH:mm:ss");
      }
    },
    
    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchStationWarningList({
        ...this.query,
        ...this.pages,
      }).then((res) => {
        this.dataSource = res.data.data.records || [];
        this.total = res.data.data.total;
      });
    },
  },

  mounted() {
    this.getList();
    this.$store.dispatch("fetchStationAll");
  },
};
</script>